import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { getPostObj } from "../utils/utils"

import { Heading } from "../components/Hero"
import { Section, Columns } from "../components/Containers"
import { Image, Text } from "../components/Core"
import { CallToAction } from "../components/CTA"
import TheaterVideo from "@components/Theater/TheaterVideo"

const SingleDoctor = ({ pageContext, location, data }) => {
  const postObj = getPostObj(
    pageContext,
    data.allDoctorsJson.nodes[0],
    data.allSpanishDoctorsJson.nodes[0]
  )
  const { post, language } = postObj

  return (
    <Layout language={language} className="doctor-page" navSpacer>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={location.pathname}
        lang={language}
      />

      {!post.hasProfileImage ? (
        <Section colorBack>
          <Columns sideColumnsSize={3}>
            <div className="column">
              <Text className="has-text-centered" as="h1" text={post.heading} />
              <Text as="p" text={post.blurb} />
              {post.badge.hasBadge && (
                <div className="doctor-page__badge">
                  <Image publicId={post.badge.badgeImage} />
                </div>
              )}
            </div>
          </Columns>
        </Section>
      ) : (
        <Section colorBack zeroBottomDesktop smallSpacing>
          <Columns sideColumnsSize={1}>
            <div className="column">
              <div className="doctor-page__hero">
                <Image publicId={post.cutoutImage} />
                <div className="doctor-page__hero-content">
                  <Text as="h1" text={post.heading} />
                  <Text as="p" text={post.blurb} />

                  {post.badge.hasBadge && (
                    <div className="doctor-page__badge">
                      <Image publicId={post.badge.badgeImage} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Columns>
        </Section>
      )}

      {!post.hasBodyImages && (
        <Section>
          <Columns className="is-desktop" sideColumnsSize={1}>
            <div className="column doctor-page__about">
              <Text text={post.aboutSection.body} />
            </div>
            <div className="column is-2"></div>
            <div className="column">
              {post.aboutSection.youtube && (
                <TheaterVideo
                  videoUrl={`https://www.youtube.com/watch?v=${post.aboutSection.youtube}`}
                  controls
                  playing
                  onlyButton
                  language={language}
                  buttonClass="contained doctor-page__theater mb-2--desktop"
                />
              )}
              <div className="doctor-facts">
                {post.facts.map(({ fact }, i) => (
                  <Text key={i} text={fact.text} />
                ))}
              </div>
            </div>
          </Columns>
        </Section>
      )}

      {post.hasBodyImages && (
        <>
          <Section>
            <Columns className="is-desktop" sideColumnsSize={2}>
              <div className="column doctor-page__about">
                <Text text={post.aboutSection.body} />

                {post.aboutSection.youtube && (
                  <TheaterVideo
                    videoUrl={`https://www.youtube.com/watch?v=${post.aboutSection.youtube}`}
                    controls
                    playing
                    onlyButton
                    language={language}
                    buttonClass="contained doctor-page__theater mt-2 mx-auto"
                  />
                )}
              </div>
            </Columns>
          </Section>

          <Section>
            <Columns sideColumnsSize={2}>
              <div className="column">
                <div className="doctor-facts has-images">
                  {post.facts.map(({ fact }, i) => (
                    <div
                      className={`doctor-facts__item ${
                        fact.imageLeft ? "" : "reverse"
                      }`}>
                      <div className={fact.image ? "half" : ""}>
                        <Text key={i} text={fact.text} />
                      </div>
                      <div className={fact.image ? "half" : ""}>
                        <Image publicId={fact.image} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Columns>
          </Section>
        </>
      )}

      <CallToAction
        colorBack
        heading={post.callToAction.heading}
        blurb={post.callToAction.blurb}
        buttons={post.callToAction.buttons}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query doctorPages(
    $title: String!
    $gmbIds: [String]
    $locations: [String]
    $locationReviews: String
  ) {
    backButton: allUniquePagesJson(filter: { template: { eq: "about-us" } }) {
      nodes {
        title
      }
    }
    allDoctorsJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        gmbIds
        hasProfileImage
        hasBodyImages
        badge {
          hasBadge
          badgeImage
        }
        blurb
        cutoutImage
        aboutSection {
          body
          youtube
        }
        facts {
          fact {
            text
            imageLeft
            image
          }
        }
        callToAction {
          heading
          blurb
          buttons {
            button {
              appearance
              destination
              buttonText
              href
            }
          }
        }
      }
    }
    allSpanishDoctorsJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        hasProfileImage
        hasBodyImages
        badge {
          hasBadge
          badgeImage
        }
        topBlurb
        cutoutImage
        aboutSection {
          body
          youtube
        }
        educationResidency {
          hasThisSection
          columnWidths {
            left
            right
            center
          }
          imageObject {
            width
            imageId
          }
          textObject {
            body
            width
          }
          body
          textOrPhotoFirst
          imageId
        }
        hobbiesInterests {
          hasThisSection
          columnWidths {
            left
            right
            center
          }
          imageObject {
            width
            imageId
          }
          textObject {
            body
            width
          }
          body
          textOrPhotoFirst
          imageId
        }
        certifications {
          hasThisSection
          columnWidths {
            left
            right
            center
          }
          imageObject {
            width
            imageId
          }
          textObject {
            body
            width
          }
          body
          textOrPhotoFirst
          imageId
        }
        learnMore {
          heading
          blurb
          buttons {
            button {
              appearance
              destination
              buttonText
              href
            }
          }
        }
      }
    }
    allLocationMatches: allSocialJson(
      filter: {
        data: {
          rating: { gte: 4 }
          platformId: { in: $gmbIds }
          totalReviews: { gt: 0 }
        }
      }
    ) {
      nodes {
        data {
          rating
          totalReviews
          url
        }
      }
    }
    review: allSocialJson(
      filter: { data: { platformId: { eq: $locationReviews } } }
    ) {
      nodes {
        data {
          rating
          totalReviews
          url
        }
      }
    }
    allSocials: allSocialJson {
      nodes {
        data {
          rating
          totalReviews
          url
          platformId
        }
      }
    }
    matchingLocations: allLocationsJson(
      filter: { pageData: { title: { in: $locations } } }
    ) {
      nodes {
        locationData {
          gmbId
        }
      }
    }
  }
`

SingleDoctor.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
  data: PropTypes.shape({
    allDoctorsJson: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          heading: PropTypes.string,
          metaTitle: PropTypes.string,
          metaDescription: PropTypes.string,
          hasImages: PropTypes.bool
        })
      ).isRequired
    }).isRequired
  }).isRequired
}

export default SingleDoctor
